import "./styles.css";

import CtaTel from "../../components/CTATel";
import CtaWpp from "../../components/CTAWpp";

import content2_1 from "../../assets/content2_1.png";
import content2_2 from "../../assets/content2_2.png";
import content2_3 from "../../assets/content2_3.png";
import content2_4 from "../../assets/content2_4.png";
import content2_5 from "../../assets/content2_5.png";

const Content = () => {
  return (
    <section id="content">
      <article className="content1">
        <div className="container">
          <h2 className="title">Já sabe onde nos encontrar?</h2>

          <p className="text">
            Temos 3 unidades em São Paulo, escolha a mais próxima de você:
          </p>

          <div className="gallery">
            <div className="item">
              <div className="top">
                <p className="number">1</p>

                <p className="text_item">Unidade Mogi</p>
              </div>

              <p className="text_item_body">
                R. Manuel de Oliveira, 269 - Vila Mogilar, Mogi das Cruzes - SP,
                08773-130 | Torre 01 Andar 04 Sala 409.
              </p>
            </div>

            <div className="item">
              <div className="top">
                <p className="number">2</p>

                <p className="text_item">Unidade Campinas</p>
              </div>

              <p className="text_item_body">
                R. Clóvis Beviláqua, 207 - Jardim Brasil, Campinas - SP,
                13073-021 | Sala 12.
              </p>
            </div>

            <div className="item">
              <div className="top">
                <p className="number">3</p>

                <p className="text_item">Unidade São Miguel</p>
              </div>

              <p className="text_item_body">
                R. Dr. José Guilherme Eiras, 415 - São Miguel Paulista, São
                Paulo - SP, 08010-220.
              </p>
            </div>
          </div>

          <CtaWpp />
        </div>
      </article>

      <article className="content2">
        <div className="container">
          <h2 className="title">fotos das nossas unidades</h2>

          <div className="gallery">
            <figure>
              <img src={content2_1} alt="Paisagem 1" />
            </figure>

            <figure>
              <img src={content2_2} alt="Paisagem 2" />
            </figure>

            <figure>
              <img src={content2_3} alt="Paisagem 3" />
            </figure>

            <figure>
              <img src={content2_4} alt="Paisagem 4" />
            </figure>
          </div>

          <h2 className="title">formas de pagamento</h2>

          <figure className="middle">
            <img src={content2_5} alt="Bandeiras de Cartão" />
          </figure>

          <p className="text">
            Parcelado no cartão de crédito em até 10x sem juros, Pix, TED e link
            (venda online e empréstimo sujeita a avaliação - Caixa Econômica
            Federal).
          </p>

          <h2 className="title blue">aGENDE AGORA MESMO A SUA AVALIAÇÃO</h2>

          <p className="text">Fale conosco por Telefone ou WhatsApp:</p>

          <div className="ctas">
            <CtaTel />
            <CtaWpp text={"(11) 96647-1045"} />
          </div>
        </div>
      </article>
    </section>
  );
};

export default Content;
