import "./styles.css";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_1Mobile from "../../assets/mobile/about1_1.png";
import about2 from "../../assets/about2.png";
import AVTR1 from "../../assets/AVTR1.png";
import AVTR2 from "../../assets/AVTR2.png";
import AVTR3 from "../../assets/AVTR3.png";
import AVTR4 from "../../assets/AVTR4.png";
import AVTR5 from "../../assets/AVTR5.png";
import AVTR6 from "../../assets/AVTR6.png";
import AVTR7 from "../../assets/AVTR7.png";
import AVTR8 from "../../assets/AVTR8.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: AVTR1,
  },
  {
    id: 2,
    image: AVTR2,
  },
  {
    id: 3,
    image: AVTR3,
  },
  {
    id: 4,
    image: AVTR4,
  },
  {
    id: 5,
    image: AVTR5,
  },
  {
    id: 6,
    image: AVTR6,
  },
  {
    id: 7,
    image: AVTR7,
  },
  {
    id: 8,
    image: AVTR8,
  },
];

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">NOSSAS ESPECIALIDADES</h2>

          <p className="text">
            Além de profissionais altamente capacitados, contamos também com os
            melhores equipamentos e técnicas para entregar os melhores
            resultados aos nossos pacientes.
          </p>

          <figure className="gallery">
            <img
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? about1_1Mobile
                  : about1_1
              }
              alt="IMPLANTES DENTÁRIOS"
            />
          </figure>

          <CtaWpp />
        </div>
      </article>

      <article className="about2">
        <figure className="left">
          <img src={about2} alt="Funcionários da Clínica" />
        </figure>

        <div className="container">
          <h2 className="title">Porque escolher a bassetti odontologia?</h2>

          <p className="text">
            Oferecemos qualidade no atendimento, os melhores produtos e as
            melhores técnica com profissionais especializados para garantir o
            bem-estar e trazer de volta a felicidade no sorriso de cada
            paciente!
          </p>

          <CtaWpp />
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">VEJA AGORA ALGUNS DOS NOSSOS RESULTADOS</h2>

          <p className="text1">
            Restaurar a autoestima através de um lindo sorriso é a nossa
            maior conquista.
          </p>

          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 4
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <p className="text2">
            Tenha mais confiança para sorrir e mastigar, ainda melhor que antes.
            <br />
            <b>Fale agora conosco e agende a sua avaliação!</b>
          </p>

          <CtaWpp />
        </div>
      </article>
    </section>
  );
};

export default About;
