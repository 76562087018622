import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import header2_1 from "../../assets/header2_1.png";
import header2_1Mobile from "../../assets/mobile/header2_1.png";
import header2_2 from "../../assets/header2_2.png";
import header2_2Mobile from "../../assets/mobile/header2_2.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            Devolvemos <span>a sua</span> liberdade <span>para</span> sorrir{" "}
            <span>de novo!</span>
          </h1>

          <p className="text">
            Conte com profissionais especializados e comprometidos em trazer de
            volta o seu sorriso!
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">odontologia funcional e estética</h2>

          <p className="text1">
            PROBLEMAS COMO ESSES TE IMPEDEM DE SORRIR OU MASTIGAR?
          </p>

          <div className="gallery">
            <figure>
              <img
                src={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? header2_1Mobile
                    : header2_1
                }
                alt="Antes"
              />
            </figure>

            <figure>
              <img
                src={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? header2_2Mobile
                    : header2_2
                }
                alt="Depois"
              />
            </figure>
          </div>

          <p className="text2">NÓS TEMOS A SOLUÇÃO PERFEITA!</p>

          <p className="text3">
            Tenha mais confiança para sorrir e mastigar, ainda melhor que antes.{" "}
            <b>Fale agora conosco e agende a sua avaliação!</b>
          </p>

          <CtaWpp />
        </article>
      </section>
    </header>
  );
};

export default Header;
